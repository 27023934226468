@use 'css/constants.scss';
@use 'css/colorpalette.scss';



#flowterTemplates {
	height: 100%;
	max-height: calc(100vh - constants.$navbarHeight);
}

#flowterTemplatesContainer {
	height: 100%;
	border-right: 1px solid #ddd;
}

.flowterTemplatesButton {
	position: absolute;
	background-color: colorpalette.$pastel;
	top: 45%;
	height: 100px;
	padding: 0 !important;
	margin: 0 !important;
	z-index: 99;
	width: 70px;
	margin-left: -70px !important;
	border-right: none;
	border-left: 1px solid colorpalette.$grey-light;
	border-bottom: 1px solid colorpalette.$grey-light;
	border-top: 1px solid colorpalette.$grey-light;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
	transition: all 0.2s;
	color: colorpalette.$white;
}

.flowterTemplatesButton:hover {
	background-color: colorpalette.$pastel;
	transition: all 0.2s;
	color: colorpalette.$midnightblue;
	width: 90px;
	margin-left: -90px !important;
}

.templateSpacer {
	height: 2px;
	border-radius: 10px;
	background-color: colorpalette.$pastel;
	width: 70%;
	margin: 0 auto;
}

.flowterTemplatesButton p {
	font-size: 10pt;
	font-weight: 600;
	transform: rotate(-90deg);
}

.templateContainer {
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	word-break: normal !important;
	/* Hide the scrollbar */
	margin-right: -5px;
	position: relative;
	z-index: 100;
}

.template:hover {
	border: 4px solid colorpalette.$midnightblue;
}

.lines {
	background-color: #0000;
	color: #0000;
	border: #0000;
	height: 2px;
}

.flowterTemplates {
	border-left: 1px solid colorpalette.$grey-light;
	position: fixed;
	width: 33.33333%;
	transition: all 1s ease-in-out;
	z-index: 99;
	top: 0;
	height: 100%;
	margin-top: 0 !important;
}

.templateCategory {
	border: 2px solid colorpalette.$grey-light;
	height: 150px;
	border: 2px solid colorpalette.$grey-light !important;
	color: colorpalette.$black !important;
	cursor: pointer !important;
	margin-left: 10px;
	margin-right: 10px;
}

.templateCategory p {
	word-wrap: break-word !important;
	white-space: normal !important;
	word-break: keep-all !important;
}

.templateCategory:hover {
	color: colorpalette.$pastel !important;
	font-weight: bold !important;
	font-size: 30px !important;
	border: 4px solid colorpalette.$pastel !important;
}

.flowterTemplatesVisible {
	right: 0;
}

.flowterTemplatesHidden {
	right: -33.3333%;
}

.dividers {
	background-color: colorpalette.$pastel;
	height: 5px;
}
@media only screen and (max-width: 1024px) {
	#flowterTemplates {
		height: auto !important;
		max-height: none !important;
	}

	#flowterTemplatesContainer {
		border: none !important;
	}
}
@media only screen and (max-width: 768px) {
	/* don't show close button if the templates are beneath the formCreator*/
	.closeButtonDesktop {
		opacity: 0;
	}

	.flowterTemplatesButton {
		position: inherit;
		opacity: 0;
	}

	.flowterTemplates {
		position: inherit;
		width: 90%;
		margin: 0 auto !important;
		border: none;
	}

	.templateContainer {
		overflow: inherit;
	}
}