@use "colorpalette.scss";

.rnc__notification-item--success {
  background-color: colorpalette.$white !important;
  color: black !important;
  border: 1px solid colorpalette.$primary;
  border-radius: 10px;
  border-left: 8px solid colorpalette.$primary !important;
  font-weight: 600;
}

.rnc__notification-item--danger {
  background-color: colorpalette.$white !important;
  color: black !important;
  border: 1px solid #ff3860;
  border-radius: 10px;
  border-left: 8px solid #ff3860 !important;
  font-weight: 600;
}

.rnc__notification-title {
  color: black !important;
}

.rnc__notification-message {
  color: colorpalette.$grey !important;
}

//.react-notification-root .timer
.rnc__notification-item--success .rnc__notification-timer,
.rnc__notification-item--danger .rnc__notification-timer {
  background-color: colorpalette.$white !important;
}

//.notification-success .timer-filler
.rnc__notification-item--success .rnc__notification-timer-filler {
  background-color: colorpalette.$primary;
}

// .notification-danger .timer-filler
.rnc__notification-item--danger .rnc__notification-timer-filler {
  background-color: #ff3860;
}
