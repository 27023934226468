@use "sass:list";
@use "colorpalette.scss";
@use "constants.scss";

.layoutInputField {
  width: 50px !important;
  padding: 0 !important;
  height: 30px !important;
}

.has-margin-auto {
  margin: 0 auto;
}

.has-margin-left-auto {
  margin-left: auto !important;
}

.has-margin-right-auto {
  margin-right: auto;
}

.has-margin-top-auto {
  margin-top: auto;
}

.bg-gradient {
  background: linear-gradient(
    40deg,
    rgba(255, 145, 77, 1) 10%,
    rgba(255, 255, 255, 1) 100%
  ) !important;
}

.grow {
  transition: transform 0.4s ease;
}

.grow:hover {
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
  transition: transform 0.4s ease;
}

.growBig {
  transition: transform 0.4s ease;
}

.growBig:hover {
  -webkit-transform: scale(1.11);
  -ms-transform: scale(1.11);
  transform: scale(1.11);
  transition: transform 0.4s ease;
}

.has-min-width {
  min-width: 280px;
}

.align-self-center {
  align-self: center;
}

.icon-circle {
  height: 30px;
  width: 30px;

  border-radius: 100%;
  display: block;
  text-align: center;
}

.icon-circle i {
  line-height: 25px;
}

.has-items-bottom {
  align-items: flex-end;
}

/*
	DISPLAY
*/
.is-in-center {
  display: block;
  margin: 0 auto;
}

.is-list-item {
  display: list-item !important;
  list-style: disc !important;
}

.has-flex-1 {
  flex: 1;
}

.has-flex-2 {
  flex: 2;
}

.has-flex-3 {
  flex: 3;
}

.flex-grow {
  flex-grow: 1;
}

.flex-direction-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.justify-content-end {
  justify-content: end;
}

.has-content-centered {
  justify-content: center;
  align-items: center;
}

.has-content-centered-vertically {
  justify-content: center;
}

.has-content-end {
  justify-content: end;
  align-items: end;
}

.buttonLoader {
  height: 22px;
  width: 22px;
  margin-top: 5px;
}

.has-line-height-full {
  line-height: 100%;
}

.has-line-height-30 {
  line-height: 30px;
}

.has-line-height-40 {
  line-height: 40px;
}

.has-line-height-50 {
  line-height: 50px;
}

.has-text-underlined {
  text-decoration: underline;
}

.has-text-crossed {
  text-decoration: line-through $grey;
  text-decoration-thickness: 1px;
}

.creatorInput {
  border: 1px solid #ddd !important;
  border-radius: 10px !important;
}

.has-no-background {
  background: none !important;
}

.creatorInput:active {
  box-shadow: none;
}

.has-overflow-y-auto {
  overflow-x: hidden;
  overflow-y: auto !important;
}

.numberCircle {
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 7px;
  text-align: center;
  width: 1.6em;
}

.flowdustFont {
  font-family: "Pacifico" !important;
}

.has-hyphens {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.has-max-z-index {
  z-index: 2147483647 !important;
}

.has-info .popup-content {
  background-color: colorpalette.$midnightblue !important;
  border-radius: 10px !important;
  border: none !important;
  z-index: 999 !important;
}

.has-info .popup-content p {
  color: colorpalette.$white !important;
}

.has-info .popup-arrow {
  background-color: colorpalette.$midnightblue !important;
}

.has-info .has-hover-icon:hover {
  color: colorpalette.$midnightblue !important;
}

.dont-allow-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.is-hover-primary:hover {
  background-color: colorpalette.$primary !important;
  color: colorpalette.$white !important;
}

.font-shadow {
  text-shadow: 0 0 1px #d2d2d2, 0 0 2px #000000;
}

.font-shrink {
  font-size: 14px;
}

.is-hover-primary:hover p {
  color: colorpalette.$white;
}

.is-hover-primary:hover i {
  color: colorpalette.$white;
}

.has-overflow-hidden {
  overflow: hidden;
}

.has-no-pointer-events {
  pointer-events: none !important;
  cursor: default !important;
}

.has-cursor-pointer {
  cursor: pointer !important;
}

.break-word {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.show-linebreaks {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
}

.zoom:hover {
  letter-spacing: 1px;
  -webkit-box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
}

.has-hover-black:hover {
  color: colorpalette.$black !important;
}

.is-underlined {
  text-decoration: underline !important;
}

.is-fixed {
  position: fixed;
}

.is-fixed-top {
  position: fixed;
  top: 0;
}

.is-absolute {
  position: absolute !important;
}

.zoom {
  transition: all 0.4s ease 0s;
}

.has-border-grey {
  border: 1px solid colorpalette.$grey-light;
}

.has-border-white {
  border: 1px solid colorpalette.$white;
}

.has-border-yellow {
  border: 1px solid colorpalette.$yellow;
}

.has-border-bottom-grey {
  border-bottom: 1px solid colorpalette.$grey !important;
}

.has-border-pinkred {
  border-bottom: 3px solid colorpalette.$pinkred !important;
}

.has-height-auto {
  height: auto;
}

.has-height-half {
  height: 50%;
}

.has-height-one-third {
  height: 33%;
}

.has-height-two-third {
  height: 66%;
}

::placeholder {
  color: colorpalette.$placeholder !important;
  opacity: 1 !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: colorpalette.$placeholder !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: colorpalette.$placeholder !important;
}
/* ====================== */
/*     Border Radius      */
/* ====================== */

$border-radiuses: (
  0,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  15,
  20,
  22,
  25,
  30,
  40,
  50,
  75
);

@each $radius in $border-radiuses {
  $i: 1;

  .br#{$radius} {
    border-radius: $radius + px !important;
  }
}

.br100 {
  border-radius: 100% !important;
}

html .has-no-br-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

html .has-no-br-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.has-border-radius-bottom-light {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.has-border-radius-top {
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
}

.has-border-radius-bottom {
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
}

/* ====================== */
/*        Width       */
/* ====================== */

$widths: (
  10,
  16,
  20,
  30,
  32,
  35,
  40,
  50,
  55,
  60,
  64,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100,
  110,
  120,
  125,
  128,
  130,
  140,
  150,
  160,
  170,
  175,
  180,
  190,
  200,
  210,
  220,
  230,
  240,
  250,
  260,
  270,
  280,
  256,
  300,
  350,
  400,
  450,
  500,
  512,
  550,
  600,
  700,
  800,
  900
);

@each $width in $widths {
  $i: 1;

  .has-width-#{$width} {
    width: $width + px !important;
  }

  .has-min-width-#{$width} {
    min-width: $width + px !important;
  }

  .has-max-width-#{$width} {
    max-width: $width + px !important;
  }

  @media (min-width: 1024px) {
    .has-max-width-#{$width}-desktop {
      max-width: $width + px !important;
    }

    .has-min-width-#{$width}-desktop {
      min-width: $width + px !important;
    }
  }

  @media (min-width: 769px) {
    .has-max-width-#{$width}-tablet {
      max-width: $width + px !important;
    }

    .has-min-width-#{$width}-tablet {
      min-width: $width + px !important;
    }
  }

  @media (max-width: 768px) {
    .has-max-width-#{$width}-mobile {
      max-width: $width + px !important;
    }

    .has-min-width-#{$width}-mobile {
      min-width: $width + px !important;
    }
  }
}

/* ====================== */
/*        Height          */
/* ====================== */

$heights: (
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  16,
  20,
  30,
  32,
  40,
  50,
  60,
  64,
  70,
  75,
  100,
  125,
  128,
  130,
  140,
  150,
  160,
  170,
  175,
  180,
  190,
  200,
  250,
  256,
  300,
  320,
  350,
  375,
  400,
  500,
  550,
  560,
  570,
  580,
  590,
  600,
  700,
  800,
  900,
  1000
);

@each $height in $heights {
  $i: 1;

  .has-height-#{$height} {
    height: $height + px !important;
  }

  .has-min-height-#{$height} {
    min-height: $height + px !important;
  }

  .has-max-height-#{$height} {
    max-height: $height + px !important;
  }
}

.is-fullwidth {
  width: 100vw !important;
}

.has-min-fullheight {
  min-height: 100%;
}

.has-max-fullheight {
  max-height: 100% !important;
}

// $navbarHeight:110px;
// $navbarHeight: 70px;

.is-fullheight {
  height: 100% !important;
  min-height: calc(100vh - constants.$navbarHeight) !important;
}

.is-max-fullheight {
  height: 100% !important;
  max-height: calc(100vh - constants.$navbarHeight) !important;
}

$popupHeight: constants.$navbarHeight * 2 + 20px;
$popupHeightMobile: constants.$navbarHeight + 10px;

.is-max-fullheight-popup {
  height: 100% !important;
  max-height: calc(100vh - $popupHeight) !important;
}

@media (max-width: 768px) {
  .is-max-fullheight {
    max-height: none !important;
  }

  .is-max-fullheight-popup {
    max-height: calc(100vh - $popupHeightMobile) !important;
    margin: 0px auto !important;
    margin-top: 110px !important;
    width: 97%;
  }
}

/* ====================== */
/*        Position        */
/* ====================== */

.top-0 {
  top: 0;
}

.top5 {
  top: 5px;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

/* ====================== */
/*    Margin / Padding    */
/* ====================== */
$sizes: (
  0,
  3,
  5,
  7,
  10,
  12,
  15,
  20,
  25,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
  110,
  120,
  130,
  140,
  150,
  160,
  180,
  200
);
$positionsShort: ("t", "l", "b", "r");
$positions: ("top", "left", "bottom", "right");
@each $size in $sizes {
  $i: 1;

  .m#{$size} {
    margin: $size + px !important;
  }

  .nm#{$size} {
    margin: -$size + px !important;
  }

  .p#{$size} {
    padding: $size + px !important;
  }
  @each $position in $positions {
    $posShort: list.nth($positionsShort, $i);
    $i: $i + 1;

    .m#{$posShort}#{$size} {
      margin-#{$position}: $size + px !important;
    }

    .p#{$posShort}#{$size} {
      padding-#{$position}: $size + px !important;
    }

    .nm#{$posShort}#{$size} {
      margin-#{$position}: -$size + px !important;
    }
  }
}

.has-max-width-100-percent {
  max-width: 100% !important;
}

.has-width-auto {
  width: auto !important;
}

.turnImg {
  transform: scaleX(-1);
}

.flip {
  transform: scaleX(-1);
}

.flipy {
  transform: scaleY(-1);
}

.min-height-400 {
  min-height: 400px !important;
}

.min-height-50 {
  min-height: 50px;
}

.no-shadow {
  box-shadow: none !important;
}
/* ====================== */
/*        BORDER          */
/* ====================== */

.has-border-white-semibold {
  border: 2px solid colorpalette.$white;
}

.has-border-white-bold {
  border: 3px solid colorpalette.$white;
}

.has-border-black {
  border: 2px solid colorpalette.$black;
}

.has-border-black-bold {
  border: 3px solid colorpalette.$black;
}

.has-no-border {
  border: none !important;
}

html div .has-no-border-bottom {
  border-bottom: colorpalette.$grey-light !important;
}

.top-0 {
  top: 0;
}

.has-border-primary {
  border: 1px solid colorpalette.$primary !important;
}

.has-border-primary-bold {
  border: 2px solid colorpalette.$primary !important;
}

.has-border-blue {
  border: 3px solid colorpalette.$blue !important;
}

.has-border-success {
  border: 2px solid #3ec46d !important;
}

.has-border-blackbold {
  border: 3px solid colorpalette.$black !important;
}

.has-border {
  border: 1px solid colorpalette.$grey-light !important;
}

.has-border-pastel-slim {
  border: 1px solid colorpalette.$pastel !important;
}

.has-border-black-slim {
  border: 1px solid colorpalette.$black !important;
}

.has-border-midnightblue-slim {
  border: 1px solid colorpalette.$midnightblue !important;
}

.has-border-top {
  border-top: 1px solid colorpalette.$grey-light !important;
}

.has-border-top-grey {
  border-top: 1px solid colorpalette.$grey !important;
}

.has-border-top-grey-light {
  border-top: 1px solid colorpalette.$grey-light !important;
}

.has-border-left-grey-light {
  border-left: 1px solid colorpalette.$grey-light !important;
}

.has-border-right-grey-light {
  border-right: 1px solid colorpalette.$grey-light !important;
}

.has-border-right-grey {
  border-right: 1px solid colorpalette.$grey !important;
}

.has-border-semibold {
  border: 2px solid colorpalette.$grey-light !important;
}

.has-border-bold {
  border: 3px solid colorpalette.$grey-light !important;
}

.has-border-bold-pastel {
  border: 4px solid colorpalette.$pastel !important;
}

.has-border-semibold-pastel {
  border: 3px solid colorpalette.$pastel !important;
}

.has-border-bottom-light-pastel {
  border-bottom: 1px solid colorpalette.$pastel !important;
}

.has-border-bottom-pastel-bold {
  border-bottom: 4px solid colorpalette.$pastel !important;
}

.has-border-top-pastel-bold {
  border-top: 4px solid colorpalette.$pastel !important;
}

.has-border-bold-purple {
  border: 4px solid colorpalette.$purple !important;
}

.has-border-bold-midnightblue {
  border: 5px solid colorpalette.$midnightblue !important;
}

.has-border-bottom {
  border-bottom: 1px solid colorpalette.$grey-light !important;
}

.has-border-bottom-bold {
  border-bottom: 2px solid colorpalette.$grey-light !important;
}

.break-all {
  word-break: break-all !important;
}

.abbreviate {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.has-white-space {
  white-space: normal;
}

.has-height-auto {
  height: auto;
}

.cleanButton {
  border: none;
  background: none !important;
  cursor: pointer !important;
  outline: none !important;
}

div .cleanButton:focus {
  outline: 0 !important;
  border: none !important;
  // box-shadow: none!important;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

input[type="text"],
input[type="password"] {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #a5a5a5;
  border-radius: 0;
}

.menu-list a:hover {
  color: #ff7f47 !important;
  border-radius: 10px !important;

  p {
    color: $grey !important;
  }
}

.hoverLink {
  font-size: 18px !important;
}

.hoverLink:hover {
  background-color: #ffffff;
  color: #ff7f47 !important;
}

.transform-horizontal {
  transform: scaleX(-1);
}

.divider {
  width: 100%;
  margin: 50px auto;
  border-bottom: 1px solid #a5a5a5;
}

.divider-medium {
  width: 100%;
  margin: 30px auto;
  border-bottom: 2px solid #ddd;
}

.lines {
  background-color: #0000;
  color: #0000;
  border: #0000;
  height: 2px;
}

.websiteSpacerl {
  background-color: #ffff !important;
  padding-bottom: 20px !important;
  border-top-left-radius: 22px !important;
  border-top-right-radius: 22px !important;
  border-bottom-left-radius: 80% !important;
  border-bottom-right-radius: 80% !important;
  border-bottom: 5px solid #ff914d;
}

.imageBack {
  background-size: cover;
  background-position: center;
  padding-bottom: 100px !important;
}

.has-fullheight {
  height: 100% !important;
}

.has-fullwidth {
  width: 100% !important;
}

.has-maxWidth {
  max-width: 100% !important;
}

.has-hover:hover {
  background-color: colorpalette.$grey-light !important;
}

.dont-select-text {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.boxshadow {
  // border: 2px solid #ddd;
  //box-shadow: 1px 2px 6px 1px rgba(49,12,82,.2) !important;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02) !important;
}

.boxshadow-bold {
  // border: 2px solid #ddd;
  box-shadow: 2px 2px 9px 2px rgba(49, 12, 82, 0.2) !important;
}

.has-border-2 {
  border: 2px solid #ddd;
  border-color: #ddd !important;
}

.has-border-1 {
  border: 1px solid #ddd;
  border-color: #ddd !important;
}

* {
  outline: none !important;
}

*:focus {
  outline: 0 !important;
}

.has-shadow {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
}

.is-center {
  margin: 0 auto !important;
}

.is-centered-bottom {
  align-content: flex-end;
}

body {
  background-color: #f2f2f6;
}
