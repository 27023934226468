@use "sass:color";
@use 'colorpalette.scss';

$bottomColor: #b7b7b7;
$topColor: color.adjust($bottomColor, $lightness: 2%);
$border: $bottomColor;

.browser-window {
	border-radius: 22px;
}

.browser-window .top-bar {
	height: 40px;
	border-radius: 22px 22px 0 0;
	background-color: colorpalette.$white;
	border: 1.5px solid #ddd;
	border-bottom: none;
}

.browser-window .circle {
	height: 8px;
	width: 8px;
	margin-left: 5px;
	display: inline-block;
	border-radius: 50%;
	background-color: #dddddd;
}

.browser-window .circles {
	margin-top: 6px;
	margin-left: 20px;
}

.browser-window .searchBar {
	border-radius: 10px;
	background-color: #e2e2e2;
	width: 100%;
	height: 25px;
	margin-top: 7px;
	margin-bottom: 5px;
}

.browser-window .circle:first-child {
	margin-left: 10px;
}

.browser-window .dev-tools .bar {
	margin-top: -4px;
	border-top: thin solid $topColor;
	border-bottom: thin solid $topColor;
	color: $topColor;

	.dev-bar-content {
		padding: 10px;
		float: left;
	}
}
