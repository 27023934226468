#tooltip {
	position: absolute;
	// display: flex;
	// align-items: center;
	// justify-content: center;
	text-align: center;
	width: 100%;
	font-weight: bold;
}

#tooltipPie {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	font-weight: bold;
}

.icon {
	border: 1px solid #ddd;
	padding: 10px;
	border-radius: 10px;
}
