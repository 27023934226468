@media (max-width: 768px) {
    #overlay {
        width: 100vw;
        height: 100vh;
        z-index: 998;
        background-color: none;
        top: 0;
        left: -100vw;
        position: fixed;

        -webkit-transform: translateX(0);
        transform: translateX(0);


        &.mobileOpen {
                -webkit-transform: translateX(100vw);
                transform: translateX(100vw);
            }
    }
    #sidebar {
            width: 120px;
            height: 100%;
            max-height: 100vh;
            position: fixed;
            top: 0;
            left: -120px;
            -webkit-transform: translateX(0);
            transform: translateX(0);
            -webkit-transition: .3s ease all;
            transition: .3s ease all;
            z-index: 2147483647;
            background-color: white;
            border-right: 1px solid #ddd;

            overflow-y: auto;
            overflow-x:hidden;
    
            &.mobileOpen {
                -webkit-transform: translateX(120px);
                transform: translateX(120px);
            }
        }

        #navbarButton {
            position: fixed;
            top: 50%;
            z-index: 1000;
            left: 0px;

            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;

            -webkit-transform: translateX(0);
            transform: translateX(0);
            -webkit-transition: .3s ease all;
            transition: .3s ease all;

            border: 1px solid #ddd;

            &.mobileOpen {
                    -webkit-transform: translateX(120px);
                    transform: translateX(120px);
                }
        }
}