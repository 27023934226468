@import '../../../../css/colorpalette.scss';

.template-hover:hover {
	background-color: $pastel;
	color: black !important;
}

.template {
	cursor: pointer !important;
	height: 100%;
}