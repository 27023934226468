@use 'css/constants.scss';

$flowdust: #ff7f47;

.navbarNonAuth {
	.navbar-dropdown-trigger {
		border-radius: 12px;
		padding: 7px 10px;
	}

	.navbar-start {
		padding-top: 0;
	}

	.navbar-dropdown-trigger:hover {
		background-color: #fafafa;
		color: black;
	}

	.navbar-item {
		margin-left: 5px !important;
		margin-right: 5px !important;
		border-radius: 12px;
		padding: 7px 10px;
	}

	.navbar-burger {
		position: relative;
		top: 7px;
		right: 10px;
	}
	@media(max-width: 1025px) {
		.navbar-menu {
			border-radius: 22px;
			border-bottom: 1px solid #ddd;
			position: relative;
			top: -5px;
		}

		.navbar-start {
			padding-top: 10px !important;
		}

		.navbar-dropdown-trigger {
			border-radius: 0;
		}
	}
}

.navigationBarAuth {
	//height: 110px !important;
	height: constants.$navbarHeight;
	
	.navbar-brand {
		align-items: center;
		justify-content: end;
	}

	.flowdustNavbar {
		position: relative !important;
		left: 50% !important;
		margin-left: -64.25px !important;
	}
	@media(max-width: 1025px) {
		.flowdustNavbar {
			position: absolute !important;
			top: 10px;
		}

		.navbar-end {
			margin-left: 10px;
			margin-right: 10px;
		}

		.navbar-end button {
			width: 100%;
		}

		.navbar-item {
			padding: 0;
			margin-top: 10px;
		}

		.navbar-end .navbar-item .buttons button,
		.navbar-start a {
			width: 100%;
		}

		.navbar-start {
			margin-left: 10px;
			margin-right: 10px;
		}

		.navbar-burger {
			margin-right: 10px;
		}

		.navbar-menu {
			border-top-right-radius: 22px;
			border-top: 1px solid #ddd;
			border-bottom: 1px solid #ddd;
		}
	}
}

.navigationBarAuth {
	padding: 0 !important;
	margin: 0 !important;
	font-family: "Pacifico" !important;
	border-radius: 0 !important;
	border-bottom: 1px solid #ddd;
	z-index: 32;
	border-bottom-right-radius: 22px !important;
}

body .navigationBar {
	padding: 0 !important;
	margin: 0 !important;
	// position: fixed;
	top: 0;
	font-family: "Pacifico" !important;
	border-radius: 0 !important;
	//border-bottom: 2px solid #ddd;
	border-bottom-right-radius: 22px !important;
	border-bottom-left-radius: 22px !important;
	width: 100%;
	height: 70px;
	z-index: 2147483647 !important;
}

.navigationBar a.navbar-item:focus {
	color: $flowdust;
	border-radius: 10px;
}

.navigationBarMargin {
	margin-top: constants.$navbarHeight;
}

.navbar {
	height: 70px;
}

.navbarButton {
	align-self: center;
	height: auto !important;
	padding: 7px 20px 7px 20px !important;
}

.navbar-icon {
	max-height: none !important;
	height: 48px;
	width: 48px;
}

.navbar-item:hover {
	border-radius: 10px;
}

.flowdustNavbar {
	color: $flowdust;
	font-family: "Pacifico" !important;
	align-self: center;
}

@media(max-width: 768px) {
.navigationBarMargin {
		// margin-top: 135px !important;
		margin-top: constants.$navbarHeight !important;
	}
}

@media(max-width: 1025px) {
	.navigationBarMargin {
		// margin-top: 110px;
		margin-top: constants.$navbarHeight;
	}

	.flowdustNavbar {
		display: inline-block;
		position: relative;
		padding: 0;
	}

	.navbar-dropdown-trigger {
		display: block !important;
		border-bottom: 1px solid #ddd;
		padding: 10px;
		justify-content: flex-start !important;
		display: block !important;
	}

	.popup-content.navbar-popup-content {
		position: relative !important;
		width: 100% !important;
		box-shadow: none !important;
		top: 0 !important;
		left: 0 !important;
		padding: 0 !important;
		border-radius: 0 !important;
	}

	.popup-content.navbar-popup-content div {
		padding: 0 !important;
	}

	.navigationBar .navbar-burger,
	.navigationBarAuth .navbar-burger {
		border-radius: 10px;
	}

	.navigationBarAuth {
		padding-top: 5px !important;
		padding-bottom: 5px !important;
		z-index: 40 !important;
	}

	.navigationBar .navbar-brand {
		width: 100%;
	}

	.navbar-brand {
		height: 100%;
	}

	.navigationBar .navbar-menu,
	.navigationBarAuth .navbar-menu {
		position: relative;
		border-bottom-right-radius: 22px;
		border-bottom-left-radius: 22px;
		margin-left: 0 !important;
		// display: block !important;
		box-shadow: none !important;
	}

	.navigationBar .navbar-start .navbar-item {
		border-bottom: 1px solid #ddd;
		border-radius: 0;
	}

	.navigationBar .navbar-menu {
		border-top: 1px solid #ddd;
		padding-top: 0;
		margin-top: 0;
	}

	.navigationBar .navbar-start {
		width: 98%;
		margin: 0 auto 20px;
	}

	
}
