#checkoutPreviousButton {
	position: relative;
	top: 50px;
	left: 50px;
}

@media (max-width: 768px) {
	#checkoutPreviousButton {
		left: 10px;
	}
}
