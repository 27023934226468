@import '~css/colorpalette.scss';
@import '~css/browserWindow.scss';
@media (max-width: 1023px) {
	html .previewWrapper {
		position: relative !important;
		height: 800px !important;
		left: 0 !important;
		top: 0 !important;
		width: auto !important;
		margin: 0 !important;
		border-radius: 10px !important;
	}

	.browser-window-wrapper {
		height: 100% !important;
		width: 100% !important;
	}

	.browser-window {
		margin: 0 10px;
	}
}
@media (min-width: 752px) {
	.sticky {
		top: 5px !important;
		transition: all 0.5s ease;
		height: calc(100% - 70px) !important;
	}

	.notSticky {
		transition: all 0.5s ease;
	}
}
@media (max-width: 1024px) {
	html .previewWrapper {
		padding-top: 20px !important;
		padding-bottom: 40px !important;
	}

	#previewInfo {
		margin-top: 5px;
		padding-top: 10px;
	}
}

#widgetContainer {
	width: 100%;
	//height: 100%;
	top: 0;
	left: 0;
	position: relative;
	border: none;
}

.browser-window-wrapper.mobile .resetWrapper .column {
	text-align: center !important;
}

.previewWrapper {
	//position: fixed;
	max-height: 100%;
	position: relative;
	//height: calc(100% - 140px);
	right: 0;
	z-index: 31;
	//min-width: 260px;
	padding: 0 !important;
}

#previewInfo {
	position: absolute;
	top: 10px;
	color: black;
	left: 10px;
}

.resetWrapper {
	// position: absolute;
	// top: 140px;
	// width: 66.66667%;
}

.previewWrapper .browser-window-wrapper.desktop {
	flex: none;
	//width: 66.66667%;
	width: 100%;
	transition: width 0.7s ease;
}

.previewWrapper .browser-window-wrapper.mobile {
	flex: none;
	width: 25% !important;
	transition: width 0.7s ease;
}
// .previewWrapper iframe {
// 	height: calc(100% - 30px) !important;
// }

.previewWrapper .browser-window-wrapper {
	text-align: left;
	display: inline-block;
	border-radius: 5px;
	width: 100%;
	height: 90%;
	padding: 0 !important;
	min-height: 300px;
	//height: calc(100% - 90px);
	min-width: 300px;
	display: flex !important;
	flex-direction: column;
}

.previewWrapper .browser-window {
	height: 100%;
	flex-grow: 1;
	display: flex !important;
	flex-direction: column;
}

.previewWrapper .content {
	background-image: url("~assets/images/preview-background.png");
	border: 1px solid #ddd;
/* 	border-bottom-left-radius: 22px;
	border-bottom-right-radius: 22px; */
	margin-bottom: 10px;
	flex-grow: 1;
	height: calc(100% - 40px);
}

.previewWrapper .previewButtonDesktop {
	border-radius: 10px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #ddd;
	border-right: none;
}

.previewWrapper .previewButtonDesktop:focus,
.previewWrapper .previewButtonMobile:focus {
	border-color: #ddd;
}

.previewWrapper .previewButtonDesktop.desktop {
	background-color: $white;
	color: $black;
}

.previewWrapper .previewButtonDesktop.mobile {
	background-color: $grey-light;
	color: $grey;
}

.previewWrapper .previewButtonMobile {
	border-radius: 10px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border: 1px solid #ddd;
	border-left: none;
}

.previewWrapper .previewButtonMobile.desktop {
	background-color: $grey-light;
	color: $grey;
}

.previewWrapper .previewButtonMobile.mobile {
	background-color: $white;
	color: $black;
}
