@use "css/constants.scss";

// Define the CSS custom property
:root {
  --max-navbar-height: #{constants.$navbarHeight * 2};
}

.maxNavbarHeight {
  max-height: calc(100vh - var(--max-navbar-height));
}

#navbarPadding {
}

@media (max-width: 768px) {
  .maxNavbarHeight {
    max-height: none !important;
  }

  #navbarPadding {
    padding-top: 15px;
  }
}
