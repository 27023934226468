.landing-headline {
	font-family: "Inter", sans-serif !important;
	font-weight: bold !important;
	font-style: normal !important;
	font-size: 40px;
}

#politeImage {
	width: 100%;
	max-width: 400px;
}

#controlImage {
	width: 100%;
	max-width: 250px;
}

#templateImage {
	height: 100%;
	max-height: 250px;
}

$videoFrameColor: #DEE1E6;

#videoWrapper {
	border: 4px solid $videoFrameColor;
	border-top: 0;
	border-radius: 8px;
	background-color: $videoFrameColor;
}

.videoImage {
	margin-bottom: -6px;
	border-radius: 5px;
}

.landingbar .circle {
	height: 12px;
	width: 12px;
	margin-left: 5px;
	margin-top: 13px;
	display: inline-block;
	border-radius: 50%;
	background-color: #FFFFFF;
}

.landingbar .top-bar {
	height: 40px;
	border-radius: 0 0 0 0;
	border-top: thin solid lighten($videoFrameColor, 1%);
	border-bottom: thin solid darken($videoFrameColor, 1%);
	background: $videoFrameColor;
}

.flip-horizontally {
	transform: scaleX(-1);
}

.landing-navbar {
	line-height: 33px;
}

.landing-shape-wave {
	margin-bottom: -10px;
}

.landing-shape-wave-transformed {
	transform: rotate(-180deg);
}

.landing-navbar .has-hover-pastel:hover {
	color: #ff7f47 !important;
}

.landing-navbar a {
	font-family: "Inter", sans-serif !important;
	font-weight: 600;
}

.features {
	border-top-left-radius: 50% 20%;
}

.introduction {}

.mainHeader {
	padding-bottom: 150px !important;
	padding-top: 150px !important;
}

.seperator {
	width: 100%;
	height: auto;
	background-image: url("~assets/images/background.svg");
	background-repeat: no-repeat;
	background-size: cover;
}

.backImg {
	background-image: url("~assets/images/preview-background.png");
	background-repeat: repeat;
	background-size: contain;
	width:100%;
	position: relative;

}

.spacer {
	height: 2px;
	border-radius: 10px;
	width: 70%;
	margin: 10px auto;
}

.border-beta {
	border: 1px solid #ff914d;
}

.featureWrapper {
	background-color: #ffffff;
	background-image: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ff7f47' fill-opacity='0.4'%3E%3Cpath d='M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zM10 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm40 40c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.mainHeaderImage {
	max-height: 400px;
}

.additionalInfoMaxHeight {
	max-height: 400px;
}

.additionalInfoHeight {
	height: 325px;
}

.gifpreview {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
}

.illustration img {
	width: 100%;
	display: block;
}

.additionalInfoWrapper {
	height: 600px;
}

.Collapsible {
	font-size: 20px;
	margin-top: 10px;
	color: black;
}

.Collapsible__contentInner {
	margin-top: 10px;
}

.Collapsible__trigger {
	cursor: pointer;
}

.posdown {
	position: relative;
	top: 80px;
}
