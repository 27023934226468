#headerWave {
	position: absolute;
	top: 0;
	margin-top: 70px;
	right: 0;
	width: 100%;
	max-width: 1400px;
}

#infoPageWrapper:nth-child(2) {
	position: relative;
	z-index: 2;
}
