#imgPos {
	position: relative;
	top: -100px;

}


@media (max-width: 1024px) {
	#imgPos {
		position: relative;
		top: 0px;
	}
}
