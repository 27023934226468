#banner {
	display: flex;
	flex-direction: column;
	max-width: 350px;
	min-width: 200px;
}

#banner img {
	position: relative;
	top: 1px;
}

#banner .has-flex-1 {
	max-height: 350px;
}
