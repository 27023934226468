.box {
	background-image: url("~assets/images/preview-background.png") !important;
	background-size: contain;
}

.imageWrapper {
  border-radius: 22px;
  max-width: 125px;
  max-height: 125px;
  margin: 0 auto;
}

.imageWrapper img {
  width: 100%;
  height: 100%;
  padding: 15px;
}
