.categoryButton {
	height: 185px !important;
}

.shopSystemIcons {
	width: 60px !important;
}

.categoryButtonContainer {
	min-width: 160px !important;
	max-width: 220px !important;
}

#scriptInsertContainer {
	height: 100%;
	max-height: calc(100vh - 70px);
	direction: rtl;
}

#scriptInsertContainer div {
	direction: ltr;
}
@media (max-width: 768px) {
	#scriptInsertContainer {
		height: 100%;
		max-height: none;
	}
}