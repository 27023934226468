#tooltip {
	position: absolute;
	// display: flex;
	// align-items: flex-start;
	// justify-content: center;
	text-align: center;
	width: 100%;
	font-weight: bold;
	font-size: 14px;
}

.icon {
	border: 1px solid #ddd;
	padding: 10px;
	border-radius: 10px;
}
