@import '~css/colorpalette.scss';

.flowterDummy {
	border: 3px dashed #ddd !important;
}

.flowterDummy p:last-child {
	color: hsl(0, 0%, 71%) !important;
}

.noWebsiteParagraph {
	line-height: 60px !important;
}

.flowterIndicator {
	text-align: center;
	border-right: 5px solid $pastel;
	border-radius: 0;
	position: relative;
	width: 25px;
}

.flowterIndicator p {
	transform: rotate(-90deg);
	line-height: 13px;
	font-size: 13px;
	width: 13px;
	color: $pastel;
	font-weight: 800;
	top: 55%;
	position: relative;
}