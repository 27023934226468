.ocean {
	width: 100%;
	position: relative;
	bottom: 0;
	left: 0;
	background: #ff914d;
}

.oceanWrapper {
	height: 198px;
}

.wave-flipped {
	animation: wave 12s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite !important;
	background-position: 50% !important;
	transform: scaleY(-1) !important;
}

.wave {
	background: url("~assets/images/landing/wavelanding.svg") repeat-x;
	position: absolute;
	width: 6400px;
	height: 198px;
	animation: wave 12s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
	// transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
	top: -25px;
	animation: wave 12s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 12s ease -1.25s infinite;
	opacity: 0.7;
}

.wave-without-animation {
	background: url("~assets/images/landing/wavelanding.svg") repeat-x;
	position: absolute;
	width: 6400px;
	height: 198px;
}

.wave-without-animation:nth-of-type(2) {
	top: -25px;
	opacity: 0.7;
}

.wave-without-animation-flipped {
	background-position: 50% !important;
	transform: scaleY(-1) !important;
}



@keyframes wave {
	0% {
		margin-left: 0;
	}

	100% {
		margin-left: -1600px;
	}
}
@keyframes swell {
	0%,
	100% {
		transform: translate3d(0,-25px,0);
	}

	50% {
		transform: translate3d(0,5px,0);
	}
}
