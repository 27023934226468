.handle {
	position: absolute;
	cursor: pointer;
	cursor: -webkit-grab;
	margin-top: -5px;
	margin-left: -7px;
	cursor: grab;
	touch-action: pan-x;
	border: 7px solid #fff;
     border-radius: 100px;
     height:23px;
     width:23px;
	box-shadow: 0 0.5em 1em -.125em rgba(10,10,10,.3),0 0 0 1px rgba(10,10,10,.02) !important;
}

.handle:focus {
	border-color: #fff !important;
}



.track {
	height: 15px;
}
