#billing .infoBox {
	max-width: 340px;
	min-width: 280px;
}

#billing #sideInfo {
	min-width: 300px;
	max-width: 400px;
	margin: 0 auto;
}

@media (max-width: 1007px) {
	#billing #sideInfo {
		border: none !important;
	}
}
