
// keep 16:9 aspect ratio for all videos
#container {
    width: 100%;
    padding-top: 56.25%;
    height: 0px;
    position: relative;
    min-width: 250px;
}

#container video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: black;
}