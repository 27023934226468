@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
.fade-enter {
	opacity: 0;
}

.fade-enter-active {
	opacity: 1;
	animation: fadeIn 0.3s ease-in;
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.fade-exit {
	opacity: 1;
}

.fade-exit-active {
	opacity: 0;
	animation: fadeOut 0.3s ease-in;
}

@keyframes push {
	50% {
		transform: scale(0.8);
	}
}

.push-enter-active {
	animation: push 0.3s ease 1;
}

.push-exit-active {
	animation: push 0.3s ease 1;
}
