@media (max-width: 768px) {
	.creatorButton {
		// margin: 0 auto 20px !important;
		min-width: 280px !important;
	}
}

.creatorButton {
	min-width: 300px !important;
	min-height: 430px;
}

.creatorButton a {
	color: hsl(0, 0%, 71%) !important;
}

.creatorButton img {
	max-height: 200px;
	max-width: 200px;
}

.creatorButton:nth-child(2) {
	// margin-left: 7%;
}

.creatorButtonImageWrapper {
	display: flex;
	align-items: center;
}

.creatorButtonImageWrapper img {
	margin: 0 auto;
}
