/* .popup-content > div:first-of-type {
	opacity: 0;
} */
@import '~css/colorpalette.scss';
@media (max-width: 768px) {
	.website {
		// max-width: 100% !important;
		// min-width: 250px !important;
		height: auto !important;
	}

	html .websiteIndicator {
		height: auto !important;
	}
}
@media (min-width: 768px) {
	.website {
		// margin-left: 20px;
		// margin-right: 20px;
	}
}

.website {
	min-width: 200px;
	max-width: 220px;
	margin-left: 10px;
	margin-right: 10px;
	min-height: 200px;
	// height: 224px;

}

.widgetSelectPopup:hover {
	background-color: $grey-light !important;
	transition: all 0.2s;
}

.widgetSelectPopup {
	transition: all 0.2s;
}

body .nestedPopup-content {
	position: relative !important;
	top: 0px !important;
	left: 0px !important;
	box-shadow: none !important;
}

.widgetTypeIndicator {
	font-size: 14px !important;
}

.websiteContent {
	display: flex !important;
	flex-direction: column !important;
}

.websiteContentData {
	height: 100%;
	justify-content: center;
	display: flex;
	flex-direction: column;
}

.websiteIconWrapper {
	display: flex;
	align-items: flex-end;
	flex-grow: 1;
}

.websiteIconWrapper div:first-child {
	min-height: 26px;
}

.websiteIcon {
	border-radius: 100%;
	background: $grey;
	width: 27px;
	height: 27px;
	text-align: center;
}

.websiteIcon p, .websiteIcon i {
	line-height: 27px;
	font-weight: 800;
	font-size: 12px;
}

.websiteIndicator {
	border-right: 5px solid $yellow;
}

.websiteIndicator p {
	top: 104px;
	color: $yellow;
}

.websiteURL {
	white-space: normal;
}
// Popup

.popup-content.widgetSelectionWrapper-content {
	overflow-x: hidden !important;
	overflow-y: auto !important;
	max-height: 400px !important;
	padding: 10px !important;
	box-shadow: rgba(0, 0, 0, 0.2) 0 1px 3px !important;
	width: auto !important;
	min-width: 280px;
	border: 1px solid #ddd !important;
}

.popup-content.websiteParameter-content {
	width: auto !important;
	margin-top: 10px !important;
	border: 1px solid #ddd !important;
}

@media (max-width: 768px) {
	.popup-content.widgetSelectionWrapper-content {
		//min-width: 80% !important;

	}
}


// Wrapper in Popup
// .widgetSelectionWrapper {
// 	max-height: 300px;
// 	overflow-y: auto;
// }

.websiteSpacer {
	background-color: #fff !important;
	/*background: linear-gradient(90deg, rgba(255,65,108,1) 0%, rgba(255,75,43,1) 100%); */
	padding-bottom: 20px !important;
	border-bottom-left-radius: 80% !important;
	border-bottom-right-radius: 80% !important;
}

.websiteSpacerl {
	background-color: #ffff !important;
	padding-bottom: 20px !important;
	border-top-left-radius: 22px !important;
	border-top-right-radius: 22px !important;
	border-bottom-left-radius: 80% !important;
	border-bottom-right-radius: 80% !important;
	border-bottom: 5px solid #ff914d;
}

.websiteSpacerp {
	background-color: #f2f2f6 !important;
	padding-bottom: 20px !important;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	border-bottom-left-radius: 80% !important;
	border-bottom-right-radius: 80% !important;
}
/*
different design:
.popup-content {
	border-radius: 10px !important;
	border-color: #ddd !important;
}

.widgetSelector .popup-content > div:first-of-type {
	opacity: 0;

}

.widgetSelector .popup-content {
	padding: 0 !important;
	margin-top: -11px !important;
	border: none !important;
	box-shadow: 0px 10px 10px 0 rgba(154,161,171,.15) !important;

}

.widgetSelector {
	width: 100%;
}

.has-maxWidth {
	max-width: 100% !important;
	margin: 0 auto !important;
}

.website {
	min-width: 230px !important;
}

.websiteSpacer {

	padding-bottom: 20px !important;
	border-bottom-left-radius: 80% !important;
	border-bottom-right-radius: 80% !important;
}

.websiteSpacerp {
	background-color: #ff7f47 !important;
	padding-bottom: 20px !important;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	border-bottom-left-radius: 20% !important;
	border-bottom-right-radius: 20% !important;
}

.websiteSpacerl {
	background-color: #6269f6 !important;
	padding-bottom: 20px !important;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	border-bottom-left-radius: 80% !important;
	border-bottom-right-radius: 80% !important;
}

*/
