@import '~css/colorpalette.scss';

.customDiv {
	position: relative;
	height: 50px;
	margin-right: -8px !important;
}

.removeButton {
	border-radius: 30px !important;
}

.optionPreviewWrapper {
	display: flex;
	flex-wrap: wrap;
}

.checkInfoWrapper {
	position: relative;
	display: inline-block;
}

.checkInfoWrapper .popup-content {
	//width: 70px !important;
	display: block;
	min-width: 160px;
}

.letterLimitationDisplay {
	line-height: 45px;
	position: relative;
	top: 3px;
}

.questionWrapper .is-checkradio[type="checkbox"] + label {
	word-break: break-all !important;
}

div .questionPreviewWrapper {
	position: relative;
}

.incrementBtnWrapper {
	display: inline-block;
	margin-top: -40px;
	position: relative;
	top: 22px;
	background-color: $white;
	border-radius: 40px;
	border: 1px solid #ddd;
}

.questionEmojiPicker {
	position: absolute;
	top: -5px;
	z-index: 99;
}

.incrementBtn {
	background: none;
	border: none;
	margin-top: 0;
	color: $grey;
}

.incrementValue {
	margin: -3px 2px;
	text-align: center;
	color: $pastel;
	font-weight: 800;
}

.customTest:first-child {
	margin-top: 20px;
}

.lineToQuestion {
	width: 2px;
	height: 80px;
	background-color: #ff7f47;
	top: -30px;
	left: 50%;
	margin-left: -1px;
	position: relative;
	margin-bottom: -30px;
}

.questionCircleTop {
	position: relative;
	left: 50%;
	z-index: 1;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-left: -4px;
	margin-bottom: -4px;
	background: #ff7f47;
}

.questionCircleBottom {
	position: relative;
	left: 50%;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-left: -4px;
	top: -28px;
	background: #ff7f47;
}

.questionContainer {
	height: 50px;
}

.optionWrapper .popup-content,
.questionWrapper .popup-content {
	width: auto !important;
}

.optionWrapper .popup-overlay,
.questionWrapper .popup-overlay {
	inset: inherit !important;
}

.optionWrapper .emoji-mart,
.questionWrapper .emoji-mart {
	max-width: 300px !important;
}

.previewQuestion {
	// word-wrap: break-word !important;
	word-break: normal !important;
}

.previewButton {
	flex: 1 1 auto;
	text-align: center;
}

.previewButton button {
	border: 2px solid #273443;
	border-radius: 50px;
	font-size: 16px;
	background-color: #ffffff;
	line-height: 24px;
	padding: 5px 25px;
	margin: 0 5px;
	outline: none;
	box-sizing: content-box;
}

.hideButton button {
	background: none !important;
	border: none !important;
	width: 0;
	height: 0;
	display: none;
}

.previewButton button p {
	overflow: hidden;
	word-break: break-all;
}

.previewButton button:hover {
	background-color: #273443;
	color: #ffffff;
}