@use 'css/colorpalette.scss';
@use 'css/constants.scss';

@media (max-width: 1023px) {
	#widgetCreator {
		height: auto !important;
		max-height: none !important;
	}
}

#widgetCreator {
	height: 100%;
	max-height: calc(100vh - constants.$navbarHeight);
}

#widgetCreator .statusBar {
	height: 50px;
	padding: 0 !important;
	border-bottom: 1px solid #ddd;
}

#widgetCreator .statusBar p {
	padding: 5px;
	font-weight: 800;
	line-height: 20px;
	margin-top: 10px;
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
	border-radius: 8px;
	background-color: colorpalette.$pastel;
	color: colorpalette.$white;
}

#widgetCreator #widgetCreatorWrapper {
	height: 100%;
}

#widgetCreatorSettings {
	height: 100%;
	border-right: 1px solid #ddd;
}

.widgetSettingsWrapper {
	height: 100%;
	flex: none;
	overflow-y: auto !important;
	border-right: 1px solid #ddd;
}