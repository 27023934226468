#ruleWrapper {
	min-height: 110px;
	cursor: text;
}

#ruleWrapper input {
	border-bottom: none !important;
	width: 100%;
	padding: 5px 5px 5px 0 !important;
	margin: 10px 0 !important;
}
