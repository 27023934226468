#wrapper {
	position: relative;
	height: calc(100vh - 130px);
	margin-top: 0px;
}

#log {
	max-height: calc(100% - 85px);
	border-bottom: 1px solid #ddd;
}
