@import '~css/colorpalette.scss';

#wrapper button:hover {
	color: $midnightblue !important;
	transition: all 0.2s;
}

#wrapper button {
	transition: all 0.2s;
}
