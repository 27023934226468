// html div .cookieButton {
// 	position: fixed;
// 	bottom: 40px;
// 	left: 40px;
// 	margin-right: 40px;
// 	min-width: 220px;
// 	z-index: 2147483647;
// 	background-color: white !important;
// 	border-radius: 10px;
// 	box-shadow: 0 8px 20px 0 rgba(0,18,46,0.16) !important;
// 	border: 1px solid rgba(0,18,46,0.16) !important;
// 	padding: 10px 15px 10px 15px;
// 	height: auto;
// }

@use 'css/constants.scss';

@media screen and (max-width: 1023px) {
	#cookieButtonWrapper {
		align-items: baseline !important;
		top: 10px !important;
	}

	#cookieButton {
		max-height: 60% !important;
	}
}

@media screen and (max-width: 768px) {

	#cookieButton {
		max-height: 55% !important;
	}
}

// $navbarHeight: 110px;
// $navbarHeight: 70px;

#cookieOverlay {
	position: fixed;
	top: 0px;
	left: 0;
	min-height: calc(100vh - constants.$navbarHeight);
	width: 100%;
	z-index: 2147483647;
}

#cookieBackground {
	position: absolute;
	top: 0;
	height: 100%;
	min-height: 100vh;
	width: 100%;
	background-color: #ddd;
	opacity: 0.8;
}

#cookieButtonWrapper {
	position: absolute;
	top: 0;
	height: 100%;
	min-height: 100vh;
	width: 100%;
	align-items: center;
}

html div #cookieButton {
	background-color: white;
	box-shadow: 0 -3px 10px 0 rgba(0,18,46,0.16);
	text-align: center;
	border-radius: 11px;
	font-weight: bold;
	overflow-y: auto;
	max-height: 80%;
}

html div .smallCookieButton {
	min-width: 48px;
}

html div .cookieButton:focus {
	box-shadow: 0 8px 20px 0 rgba(0,18,46,0.16) !important;
	border: 1px solid rgba(0,18,46,0.16) !important;
}
