#primaryPlan {
	min-width: 280px;
}

#primaryPlanLabel {
	position: relative;
	top: -14px;
	display: inline-block;
	padding-top: 0px;
	padding-bottom: 0px;
	padding-left: 15px;
	padding-right: 15px;
}
