$pastel: #ff914d;
$pastel-light: #fcb992;
$pink: #ff496c;
$purple: #6269f6;
$white: #ffffff;
$yellow_old: #FFBB47;
$turquoise: #16a3a3;
$blue: #007aff;
$orange: #f27b53;
$midnightblue: #273443;
$grey-light: #ddd;
$grey-lighter: #F0F0F3;
$grey: #7A7A7A;
$primary: $pastel;
$black: #000000;
$green: #00d1b2;
$shade: #eff1f4;
$placeholder: #b5b5b5;
$subtlegrey: #f3f6ff;
$statusWidget: #FF5140;
$pinkred: #FF5140;
$dash: #f2f2f6;
$yellow:#FFCF89;
$lightblue:#F2F5FA;
$statuslight:#fb9d94;
$textfeedback:#689CD9;
