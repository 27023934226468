@use 'colorpalette.scss';
/* ====================== */
/*        PINK            */
/* ====================== */
.fill-pink {
	fill: colorpalette.$pink !important;
}

.has-text-pink {
	color: colorpalette.$pink !important;
}

.is-pink {
	color: colorpalette.$pink !important;
}

.has-hover-pink:hover {
	background-color: #fc2a51 !important;
	color: colorpalette.$white !important;
}

.has-hover-red:hover {
	background-color: #DB302C !important;
	color: colorpalette.$white !important;
}


.has-hover-white:hover {
	background-color: colorpalette.$white !important;
	color: colorpalette.$pink !important;
}

.has-background-pink {
	background-color: colorpalette.$pink !important;
}

.has-border-pink {
	border: 2px solid colorpalette.$pink !important;
}
/* ====================== */
/*         Pastel         */
/* ====================== */
.fill-pastel {
	fill: colorpalette.$pastel !important;
}

.is-pastel {
	color: colorpalette.$white !important;
	background-color: colorpalette.$pastel !important;
}

.has-background-pastel {
	background-color: colorpalette.$pastel !important;
}

.has-background-pastel-light {
	background-color: colorpalette.$pastel-light !important;
}

.has-text-pastel {
	color: colorpalette.$pastel !important;
}



.has-border-pastel {
	border: 2px solid colorpalette.$pastel !important;
}

.has-border-top-pastel {
	border-top: 2px solid colorpalette.$pastel !important;
}

.has-border-pastel-light {
	border: 1px solid colorpalette.$pastel !important;
}

.has-border-bottom-pastel-light {
	border-bottom: 1px solid colorpalette.$pastel !important;
}

.has-hover-pastel:hover {
	background-color: colorpalette.$pastel !important;
	color: colorpalette.$white !important;
}
/* Checkradio boxes in pastel */

.is-checkradio[type="radio"].is-pastel:checked + label::after,
.is-checkradio[type="radio"].is-pastel:checked + label::after {
	border-color: colorpalette.$pastel !important;
	background-color: colorpalette.$pastel !important;
}

.is-checkradio[type="radio"].is-pastel:hover:not([disabled]) + label::before,
.is-checkradio[type="radio"].is-pastel:hover:not([disabled]) + label::before {
	border-color: colorpalette.$pastel !important;
}
/* ====================== */
/*         Purple         */
/* ====================== */
.fill-purple {
	fill: colorpalette.$purple !important;
}

.has-text-purple {
	color: colorpalette.$purple !important;
}

.has-background-purple {
	background-color: colorpalette.$purple !important;
}


.is-purple {
	color: #6269f6 !important;
}

.has-hover-purple:hover {
	background-color: #565de2 !important;
}
/* ====================== */
/*         pinkred       */
/* ====================== */
.fill-purple {
	fill: colorpalette.$pinkred !important;
}

.has-text-pinkred {
	color: colorpalette.$pinkred!important;
}

.has-background-pinkred {
	background-color: colorpalette.$pinkred !important;
}

.is-pinkred {
	color: #FF5140 !important;
}
/* ====================== */
/*     Midnightblue       */
/* ====================== */
.has-background-midnightblue {
	background-color: colorpalette.$midnightblue !important;
}

.has-text-midnightblue {
	color: colorpalette.$midnightblue !important;
}

.has-border-midnightblue {
	border: 2px solid colorpalette.$midnightblue !important;
}

.has-border-midnightblue-light {
	border: 1px solid colorpalette.$midnightblue !important;
}


/* ====================== */
/*        Red       */
/* ====================== */
.has-text-red {
	color: #DB302C !important;
}

.has-background-red {
	background-color: #DB302C !important;
}
/* ====================== */
/*        Blue       */
/* ====================== */
.has-text-blue {
	color: colorpalette.$blue !important;
}

.has-background-blue {
	background-color: colorpalette.$blue !important;
}

.has-hover-blue:hover {
	color: white !important;
	background-color: colorpalette.$blue !important;
}
/* ====================== */
/*        Turquoise   */
/* ====================== */
.has-text-turquoise {
	color: colorpalette.$turquoise !important;
}

.has-background-turquoise {
	background-color: colorpalette.$turquoise!important;
}
/* ====================== */
/*         Yellow         */
/* ====================== */
.has-text-yellow {
	color: colorpalette.$yellow !important;
}

.has-border-top-yellow {
	border-top: 2px solid colorpalette.$yellow !important;
}

.has-border-bottom-yellow {
	border-bottom: 2px solid colorpalette.$yellow !important;
}

.has-hover-yellow:hover {
	background-color: #fea511 !important;
}

.has-background-yellow {
	background-color: colorpalette.$yellow !important;
}

/* ====================== */
/*       Hub  */
/* ====================== */
.has-text-lightblue {
	color: colorpalette.$lightblue !important;
}

.has-background-lightblue {
	background-color: colorpalette.$lightblue !important;
}
/* ====================== */
/*          Shades          */
/* ====================== */
.has-background-shade {
	background-color: #eff1f4 !important;
}

.has-background-dash {
	background-color: colorpalette.$dash !important;
}

.has-text-dash {
	color: colorpalette.$dash !important;
}
/* ====================== */
/*          Subtle Grey         */
/* ====================== */
.has-background-subtlegrey {
	background-color: colorpalette.$subtlegrey !important;
}

.has-text-subtlegrey {
	color: colorpalette.$subtlegrey !important;
}
/* ====================== */
/*          Green          */
/* ====================== */
.has-background-green {
	background-color: colorpalette.$green !important;
}
/* ====================== */
/*          Primary          */
/* ====================== */

.has-hover-primary:hover {
	background-color: #ff7a2d !important;
	color: #ffffff !important;
}

.has-hover-text-primary:hover {
	color: #ff7a2d !important;
}

.has-hover-border-primary:hover {
	border: 2px solid colorpalette.$primary !important;
}
/* ====================== */
/*          Grey          */
/* ====================== */

.has-hover-grey:hover {
	color: colorpalette.$grey !important;
}

.has-border-grey-bold {
	border: 3px solid colorpalette.$grey !important;
}

.has-border-grey {
	border: 2px solid colorpalette.$grey !important;
}

.has-border-grey-thin {
	border: 1px solid colorpalette.$grey !important;
}

.has-border-grey-top {
	border-top: 2px solid colorpalette.$grey  !important;
}

.has-border-grey-top-thin {
	border-top: 1px solid colorpalette.$grey  !important;
}

.has-border-grey-bottom {
	border-bottom: 2px solid colorpalette.$grey  !important;
}

.has-border-grey-bottom-thin {
	border-bottom: 1px solid colorpalette.$grey  !important;
}

/* ====================== */
/*       Grey-Light       */
/* ====================== */

.has-border-grey-light-thin {
	border: 1px solid colorpalette.$grey-light !important;
}

.has-border-grey-light-thin-top {
	border-top: 1px solid colorpalette.$grey-light  !important;
}

.has-border-grey-light {
	border: 2px solid colorpalette.$grey-light !important;
}

.has-border-grey-light-bold {
	border: 3px solid colorpalette.$grey-light !important;
}

.has-hover-grey-light:hover {
	background-color: colorpalette.$grey-light !important;
	transition: background-color 0.2s;
}

.has-hover-grey-light {
	transition: background-color 0.2s;
}

/* ====================== */
/*      Grey-Lighter      */
/* ====================== */

.has-hover-grey-lighter:hover {
	background-color: colorpalette.$grey-lighter !important;
	transition: background-color 0.2s;
}

.has-hover-grey-lighter {
	transition: background-color 0.2s;
}

/* ====================== */
/*      Danger Color      */
/* ====================== */

.has-hover-danger:hover {
	color: #ed254d !important;
}

/* ====================== */
/*     Midnightblue Color      */
/* ====================== */

.has-hover-midnightblue:hover {
	color: colorpalette.$midnightblue !important;
	background-color: white !important;
	border: solid 2px colorpalette.$midnightblue;
}
/* ====================== */
/*      StatusWidget      */
/* ====================== */

.has-background-statusWidget {
	background-color: colorpalette.$statusWidget !important;
}
.has-text-statusWidget {
	color: colorpalette.$statusWidget !important;
}

.has-hover-statusWidget:hover {
	background-color: colorpalette.$statusWidget !important;
	color: colorpalette.$white !important;
}

.has-background-status-light {
	background-color: colorpalette.$statuslight !important;
}

/* ====================== */
/*      TextFeedbackWidget      */
/* ====================== */

.has-background-textfeedback {
	background-color: colorpalette.$textfeedback !important;
}

.has-text-textfeedback {
color: colorpalette.$textfeedback !important;
}

.has-border-textfeedback {
border: 4px solid colorpalette.$textfeedback !important;
}
