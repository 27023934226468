.privacyAgreementWindow {
	height: 300px;
	border-top: 1px solid #666666;
	border-bottom: 1px solid #666666;
	width: 80%;
	margin: 0 auto;
	overflow-y: auto;
	overflow-x: hidden;
	margin-top: 30px;
}
