div .navigationWrapper {
	border-radius: 0 !important;
	border-top-right-radius: 22px !important;
	background-color: #273443 !important;
	/* min-width: 220px !important; */
	/* min-width: 250px !important; */
}
@media (min-width: 1770px) {
	div .navigationWrapper {
		width: 13% !important;
	}
}
@media (max-width: 768px) {
	div .navigationWrapper {
		border-bottom-right-radius: 22px !important;
	}

	aside.menu {
		margin-top: 0px !important;
	}
}

.lineh {
	background-color: #FFffff;
	color: #Ffffff;
	border: #FFffff;
	height: 1px;
}

.lineh2 {
	background-color: #dddd;
	color: #dddd;
	border: #dddd;
	height: 1px;
}
