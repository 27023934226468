

.customBox {
	height: 165px !important;
}

.welcomeMessage {
	min-height: 304px;
}



#homeWrapper .infoBox {
	max-width: 340px;
	min-width: 280px;
}

#homeWrapper #sideInfoHome {
	min-width: 300px;
	max-width: 400px;
	margin: 0 auto;
}

@media (max-width: 1007px) {
	#homeWrapper #sideInfoHome {
		width: 100%;
		max-width: 100%;
	}
	#home #home {
		border: none !important;
	}
}
