@use "colorpalette.scss";

@import "~bulma-checkradio/dist/css/bulma-checkradio.min.css";
@import "~bulma-slider/dist/css/bulma-slider.min.css";

@font-face {
  font-family: "Inter";
  src: url("../webfonts/Inter.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  src: url("../webfonts/OpenSans.ttf") format("truetype");
}

@font-face {
  font-family: "Pacifico";
  src: url("../webfonts/Pacifico.ttf") format("truetype");
}

$button-border-width: 0;
$box-shadow: none;
$box-radius: 22px;

$button-focus-box-shadow-color: unset;

// Overwrite styling for paths
path {
  cursor: pointer !important;
}

.popup-empty-content {
  background: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-top: -3px !important;
  border: none !important;
  width: auto !important;
}

.popup-content {
  border-radius: 20px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02) !important;
  border: none !important;
  overflow: hidden;
  z-index: 1000 !important;
}

.popup-content.no-styling-content {
  width: auto !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  background: none !important;
  top: 0 !important;
}

@media screen and (max-width: 768px) {
  .popup-content.no-styling-content {
    height: 100vh;
    display: flex;
    align-items: center;
  }
}

.popup-corner-content {
  width: auto !important;
  border-radius: 0px !important;
  padding: 5px 0px !important;
}

.popup-width-400-content {
  width: 400px !important;
}

.popup-overlay.fixed-overlay {
  position: fixed !important;
  z-index: 900 !important;
}

.popup-overlay.no-styling-overlay {
  position: fixed !important;
}

.popup-overlay {
  position: absolute !important;
  width: 100% !important;
  z-index: 900 !important;
  //inset: -100px 0px 350px 0px !important;
}

.popup-overlay.no-inset-overlay {
  inset: 0px !important;
}

.button {
  word-wrap: break-word !important;
  max-width: 100% !important;
  white-space: normal !important;
}

.popup-width-auto-content {
  width: auto !important;
}

html {
  background-color: colorpalette.$dash !important;
}
@media (max-width: 768px) {
  .is-centered-mobile {
    justify-content: center;
  }
}

.is-checkradio[type="checkbox"] + label::after {
  border-color: #273443 !important;
}

.is-checkradio[type="checkbox"]:hover:not([disabled]) + label::before,
html
  body
  .is-checkradio[type="checkbox"]:hover:not([disabled])
  + label::before {
  border-color: #273443 !important;
}

html .input:focus {
  box-shadow: none;
}

html {
  overflow-y: auto;
}

body,
html {
  height: auto !important;
}

.progress {
  height: 10px !important;
}

.is-checkradio[type="checkbox"],
.is-checkradio[type="radio"] {
  position: relative;
}

.emoji-mart-preview-data {
  text-align: left;
}

/* Remove the dotted outline on checkradio */
label::before {
  outline: none !important;
}

td {
  padding-left: 1% !important;
  padding-right: 1% !important;
}

a.navbar-item:hover {
  color: #000000 !important;
}

.table td {
  vertical-align: middle !important;
}

.table td > * {
  vertical-align: middle !important;
}
/* Makes the last column as high as all other columns */
.columns {
  flex-wrap: wrap;
  align-items: stretch;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
input,
select,
textarea {
  //font-family: "Inter", sans-serif !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  //font-size: 12pt;
  //line-height: 1.5;
}

.button:focus:not(:active) {
  box-shadow: none !important;
}

.button {
  border: none;
}

.button:focus {
  box-shadow: none !important;
}

pre {
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  word-break: break-all !important;
}

.title {
  white-space: pre-wrap;
}

.is-active {
  font-weight: bold !important;
}

.is-size-8 {
  font-size: 0.5rem !important;
}

.is-size-9 {
  font-size: 0.25rem !important;
}

.rc-slider-handle {
  height: 25px;
  width: 25px;
  background-color: "#fff";
  border: solid 1px grey !important;
  opacity: 1;
}

.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "Gib einen Link an:";
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: "Speichern";
  color: black;
}
.ql-snow .ql-tooltip::before {
  content: "Deine URL:";
}

.ql-snow .ql-tooltip a.ql-action::after {
  content: "Bearbeiten";
  color: black;
}

.ql-snow .ql-tooltip a.ql-remove::before {
  content: "Entfernen";
  color: black;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.ql-tooltip {
  left: 0 !important;
  z-index: 1000 !important;
}

.ql-editor {
  color: black !important;
  word-wrap: anywhere;
  font-size: 1rem !important;
}

.quill-hide-toolbar .ql-container {
  border: none !important;
}

.quill-hide-toolbar .ql-toolbar {
  display: none !important;
}

@import "rc-slider/assets/index.css";
@import "react-medium-image-zoom/dist/styles.css";
@import "react-quill/dist/quill.snow.css";
