.nameDisplay {
	line-height: 300%;
}

@media (max-width: 768px) {
	.smallWidget {
		max-width: 200px !important;
		height: auto !important;
	}

	.has-max-width-mobile {
		max-width: 300px;
		margin: 0 auto;
		display: block;
	}
}

.smallWidget {
	max-width: 200px;
	min-width: 200px;
	min-height: 208px;
}
