body {
	font-family: 'Montserrat', sans-serif;
	margin: 0;
	padding: 0;
}

#onboardingButtonsWrapper {
	z-index: 39;
	position: relative;
	height: 0px;
}

.container {
	width: 600px;
	margin: 100px;
}

.progressBar {
	display: none;
}
@media (min-width: 768px) {
	.progressBarContainer {
		height: 130px;
	}

	.progressBar {
		padding: 0;
		display: block;
		counter-reset: step;
	}

	.progressBar li {
		margin-bottom: 30px;
		margin-top: 20px;
		list-style-type: none;
		width: 25%;
		float: left;
		font-size: 12px;
		position: relative;
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;
		color: #7d7d7d;
	}

	.progressBar li:before {
		width: 30px;
		height: 30px;
		content: counter(step);
		counter-increment: step;
		line-height: 25px;
		border: 2px solid #7d7d7d;
		display: block;
		text-align: center;
		margin: 0 auto 10px;
		border-radius: 50%;
		background-color: white;
		z-index: 99;
		position: relative;
	}

	.progressBar li:after {
		width: 100%;
		height: 2px;
		content: '';
		position: absolute;
		background-color: #7d7d7d;
		top: 15px;
		left: -45%;
	}

	.progressBar li:first-child:after {
		content: none;
	}

	.progressBar li.active {
		color: #55b776;
	}

	.progressBar li.active:before {
		border-color: #55b776;
	}

	.progressBar li.active + li:after {
		background-color: #55b776;
	}
}
