@import '~css/colorpalette.scss';

#websiteForm .websiteInput {
	// border: 2px solid $blue !important;
	border: none !important;
	border-bottom: 1px solid #000000 !important;
	border-radius: 0 !important;
	background: none !important;
	box-shadow: none !important;
}

#websiteName.websiteInput {
	width: 100% !important;
	max-width: 400px;
}

#websiteUrl {
	width: 100% !important;
	max-width: 600px !important;
	height: auto;
}

#websiteUrl input {
	border: none !important;
	margin-right: 5px;
	outline: none !important;
	width: 100%;
	display: inline-block;
	font-size: 17px;
}

#websiteUrl.input:active,
#websiteUrl.input:focus {
	outline: none !important;
	box-shadow: none !important;
}

#websiteUrl div:first-child {
	padding: 11px 5px 11px 0;
	margin-right: 1px;
	display: inline-block;
}

#websiteUrl div:nth-child(2) {
	width: 100%;
	padding-right: 5px;
}

#websiteUrl div p {
	line-height: 30px;
	font-size: 17px;
	margin-top: 2px;
	margin-right: 0px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
