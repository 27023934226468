.cookieSettings {
	position: fixed;
	min-width: 220px;
	left: 20px;
	margin-right: 20px;
	top: 90px;
	max-width: 300px;
	background-color: white;
	border: 1px solid #ddd;
	height: 50%;
	border-radius: 10px;
	z-index: 50;
	box-shadow: 0 8px 20px 0 rgba(0,18,46,0.16) !important;
	overflow: hidden;
	overflow-y: auto;
}

.cookieSetting {
	border-bottom: 1px solid #ddd;
}

.switch {
	vertical-align: middle;
}
@media (max-width: 768px) {
	.cookieSettings {
		position: fixed;
		top: 50%;
		margin-top: -200px;
		height: 400px;
	}
}
