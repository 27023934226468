#pricingPlanLabel {
	position: absolute;
	width: 180px;
	height: 70px;
	top: -30px;
	border-radius: 10px;
	left: 50%;
	margin-left: -90px;
	display: flex;
	justify-content: center;
	align-items: center;
}

#pricingPlan {
	min-width: 280px;
}
