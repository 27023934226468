.widgetDummy {
	height: 204px;
	min-width: 260px;
	// border: 3px dashed #ddd !important;
}

.widgetDummy p:last-child {
	color: hsl(0, 0%, 71%) !important;
}

.filterDisplay {
	line-height: 40px;
}

.noWebsiteParagraph {
	line-height: 60px !important;
}

#sortingDropdown .popup-arrow {
	display: none;
}

#sortingDropdown .popup-content {
	border: none !important;
	width: 150px !important;
	margin-top: 0;
}

#widgetContainer #sortingWrapper {
	text-align: left;
}