#wrapper {
	position: relative;
	margin-top: 0px;
}

.scrollWrapper {
    max-height: 400px;
}

#content {
	border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.scrollContent {
    max-height: 320px;
    overflow-y: auto;
}
