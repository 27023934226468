@import '~css/colorpalette.scss';

.websiteDummy a {
	color: hsl(0, 0%, 71%) !important;
}

.websiteDummy {
	height: 204px;
	min-width: 260px;
}

.noWebsiteParagraph {
	line-height: 60px !important;
}

#websiteWrapper {
	overflow: hidden;
	transition: all 1s ease;
}